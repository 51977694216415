.products-root {
	width: 100%;
    padding: 60px 0;
	background-color: #f8f8f8;
	min-height: 700px;

	.title {
		padding-top: 60px;
		font-size: 48px;
		line-height: 58px;
		color: #333;
		text-align: center;
		font-weight: 600;
	}

	.description {
		width: calc(100% - 64px);
		max-width: 1024px;
		font-size: 18px;
		color: #333;
		text-align: center;
		margin: auto;
		font-family: 'Montserrat';
		padding-top: 20px;
	}

	.products-list {
		max-width: 1200px;
		width: calc(100% - 32px);
		padding-top: 64px;
		margin: auto;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: stretch;

		.card {
			margin: 0 0 24px 24px;
			box-sizing: border-box;
			flex: 0 0 350px;
			background: #fff;
			box-shadow: 0 10px 30px rgb(194 203 207 / 30%);
			background-color: rgb(164 211 255 / 15%);
			font-family: 'Montserrat';
			text-align: justify;
			border-radius: 0.5rem;
			margin-top: 300px;

			&.visible {
				margin-top: 0px;
				transition: margin-top 300ms linear;
			}

			img {
				border-radius: 0.5rem 0.5rem 0 0;
			}

			.text {
				padding: 40px 24px;
			}

			.button {
				display: flex;

				button {
					border: 2px solid #0558ff;
					margin: 40px auto;
					border-radius: 4px;
					padding: 12px 30px;
					background: white;
					transition: background-color 500ms linear;

					&:hover {
						background-color: #e4edff;
						transition: background-color 500ms linear;
					}
				}
			}
		}

	}
}
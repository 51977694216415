.dialog-content-root {
    display: flex;
    flex-direction: column;

    .img {
        text-align: center;

        img {
            max-width: 100%;
            max-height: 450px;
        }

    }

    .title {
        text-align: center;
        font-size: 30px;
        padding-bottom: 24px;
    }

    .text {
        padding-top: 24px;
        text-align: justify;
    }

    .button-container {
        width: fit-content;
        margin: auto;
        padding: 16px;

        button {
            background: #fff;
            border: 2px solid #0558ff;
            border-radius: 4px;
            margin: 10px auto;
            padding: 12px 30px;
        }
    }
}
.menu-root {
    position: sticky;
    top: 0px;
    width: 100%;
    border-bottom: 1px solid rgb(236, 240, 242);
    background: white;
    z-index: 2;

    .wrapper {
        width: calc(100% - 32px);
        max-width: 1200px;
        margin: auto;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        padding: 16px 0;
    }

    .logo img {
        width: 140px;
        cursor: pointer;
    }

    .list {
        display: flex;
        flex-direction: row;
        color: #333333;
        text-decoration: none;
        font-family: "Montserrat";
        font-weight: 500;

        div {
            padding: 0 16px;

            span {
                cursor: pointer;

                &.active {
                    color: #0055FF;
                }
            }
        }
    }
    
    .burguer {
        display: none;
        margin-left: 16px;
        height: 100%;
        border: 1px solid #1a5cbf;
        width: 40px;
        border-radius: 5px;
        padding-top: 2px;
        cursor: pointer;
        height: 34px;
        flex-direction: column;

        & > div {
            width: 30px;
            margin: 3px auto;
            height: 3px;
            border: 1px solid #2871cd;
            background: #2871cd;
        }
    }

    .menu {
        height: calc(100vh - 75px);
        font-family: 'Montserrat';
        background: #f7f7f7;
        display: none;

        div {
            width: 100%;
            padding: 12px 24px;
            border-top: 1px solid rgb(40 113 205 / 50%);
            border-bottom: 1px solid rgb(40 113 205 / 50%);
            margin-top: -1px;
            background-color: white;
            cursor: pointer;
        }

        &.active {
            display: inherit;
        }
    }
    
    @media(max-width: 800px) {
        .list {
            display: none;
        }

        .burguer {
            display: flex;
        }
    }

    @media(min-width: 801px) {
        .menu {
            display: none !important;
        }
    }
}
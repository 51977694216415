.footer-root {
	width: 100%;
	text-align: center;
	color: white;
	font-weight: bold;
    background-color: #232f3b;

	.copyright {
		padding: 12px;
	}

	.social {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		padding-bottom: 12px;

		& > a {
			text-decoration: none;
			color: white;

			div {
				margin: 0 24px;
				background-color: #1d415c;
				width: 40px;
				height: 40px;
				border-radius: 20px;
				font-size: 23px;
				cursor: pointer;
			}

		}
	}

	.extra {
		width: 100%;
		font-weight: 200;
		background-color: #1d2730;
		padding: 6px;

		span {
			cursor: pointer;

			&:hover {
				text-decoration: underline;
			}
		}

	}
}
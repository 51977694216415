.trust-root {
	width: 100%;
    padding: 90px 0 120px 0;
	background-color: white;

	.title {

		div {
			padding-top: 30px;
			font-size: 48px;
			line-height: 58px;
			color: #333;
			text-align: center;
			font-weight: 600;
		}

		.description {
			width: calc(100% - 64px);
			max-width: 1024px;
			font-size: 18px;
			color: #333;
			text-align: justify;
			margin: auto;
			font-family: "Montserrat";
			text-align: center;
			padding-top: 0;
			padding-bottom: 70px;
		}
	}

	@mixin white-gradient {
		background: linear-gradient(to right,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
	}
	
	$animationSpeed: 40s;
	$imagesCount: 22;

	// Animation
	@keyframes scroll {
		0% { transform: translateX(0); }
		100% { transform: translateX(calc(-250px * ($imagesCount / 2)))}
	}
	
	// Styling
	.slider {
		background: white;
		height: 100px;
		overflow: hidden;
		position: relative;
		
		&::before,
		&::after {
			@include white-gradient;
			content: "";
			height: 100px;
			position: absolute;
			width: 200px;
			z-index: 1;
		}
		
		&::after {
			right: 0;
			top: 0;
			transform: rotateZ(180deg);
		}
	
		&::before {
			left: 0;
			top: 0;
		}
		
		.slide-track {
			animation: scroll $animationSpeed linear infinite;
			display: flex;
			width: calc(250px * $imagesCount);
		}
		
		.slide {
			height: 100px;
			width: 250px;
		}
	}
}
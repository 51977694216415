.contact-root {

	.slope {
		width: 100%;
		height: 48px;
		background-color: white;

		img {
			width: 100%;
			height: 48px;
		}
	}

	.content {
		background-color: #e1e3e7;
		display: flex;
		flex-direction: column;    
		padding: 50px 0;

		.text {
			padding-top: 40px;
			font-size: 48px;
			line-height: 58px;
			color: #333;
			font-weight: 600;
			display: inline-block;
			margin: auto;
			border-bottom: 2px solid #7f858a;
			text-align: center;
		}
	
		.text-secondary {
			width: calc(100% - 64px);
			max-width: 650px;
			font-size: 18px;
			color: #333 !important;
			text-align: center;
			margin: auto;
			font-family: "Montserrat";
			padding-top: 32px;
		}
	
		.mail {
			margin: auto;
			color: #008fbf;
			font-size: 25px;
			font-family: "Roboto";
		}
	
		.form {
			width: 90%;
			max-width: 1000px;
			margin: auto;
			display: flex;
			flex-wrap: wrap;
			flex-direction: column;
	
			.MuiInputLabel-root {
				color: #333333;
			}
	
			input[type='text'], textarea {
				width: 80vw;
				max-width: 700px;
			}
	
			& > button {
				margin: auto;
				margin-top: 24px;
				width: 200px;
				padding: 8px 16px;
				margin-bottom: 48px;
				font-weight: 300;
				font-family: "Montserrat";
				background: linear-gradient(130deg, #43cfff 0%, #2871cd 83%, #17539e 100%);
				border: 1px solid #3c536a;
				color: white;
				font-size: 18px;
				height: 55px;
				width: 220px;
				border-radius: 4px;

				&:disabled {
					background: #e4edf6;
					color: #1758b9;
				}
			}
	
			.message-sent {
				color: #50f550;
				font-weight: 400;
				margin-top: 20px;
			}
		}
	}
}
.services-root {
	width: 100%;
    padding: 60px 0;
	background-color: #f0f1f5;

	.title {
		padding-top: 60px;
		font-size: 48px;
		line-height: 58px;
		color: #333;
		text-align: center;
		font-weight: 600;
	}

	.description {
		width: calc(100% - 64px);
		max-width: 1024px;
		font-size: 18px;
		color: #333;
		text-align: justify;
		margin: auto;
		font-family: 'Montserrat';
		padding-top: 32px;
	}

	.services-list {
		max-width: 1200px;
		width: calc(100% - 32px);
		padding-top: 64px;
		margin: auto;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: stretch;

		.card {
			margin: 0 0 24px 24px;
			box-sizing: border-box;
			flex: 0 0 575px;
			padding: 28px 24px;
			background: #fff;
			box-shadow: 0 10px 30px rgb(194 203 207 / 30%);
			background-color: rgb(164 211 255 / 15%);
			font-family: 'Montserrat';
			text-align: justify;
			opacity: 0;
			transition: opacity 1500ms linear;

			&.show {
				opacity: 1;
				transition: opacity 1500ms linear;
			}

			.icon {
				width: 50px;
				height: 50px;
			}

			.icon-title {
				font-size: 25px;
				font-weight: 400;
				padding: 24px 0;
			}
		}

		@media(max-width: 1246px) {
			.card {
				flex: none;
				width: 100%;
				max-width: 800px;
			}
		}
	}
}
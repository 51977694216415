.header-root {
    height: 88px;
    width: 100%;
    background-color: #2871cc;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    .wrapper {
        width: calc(100% - 32px);
        max-width: 1200px;
        margin: auto;
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
        height: 100%;
        align-items: center;

        button {
            font-weight: 400;
            border-radius: 6px;
            border: 3px solid #35559b;
            background-color: #f4fcff;
            font-size: 18px;
            height: 55px;
            color: #2563c8;
            width: 220px;
            font-family: "Roboto";
            transition: background-color 0.5s ease;

            &:hover {
                background-color: #edefef;
                transition: background-color 0.5s ease;
            }
        }
    }

    @media(max-width: 800px) {
        height: 60px;

        button {
            display: none;
        }
    }
}
.video-root {
	width: 100%;
    height: calc(100vh - 220px);
    position: relative;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);

	video {
        position: absolute;
        z-index: -1;
        min-width: 100%;
        max-width: 100%;
        height: calc(100vh - 220px);
        object-fit: cover;
	}

	.content {
        position: absolute;
        width: 100%;
        height: calc(100vh - 220px);
        background-color: rgba(164, 211, 255, 0.3);
        display: flex;
        flex-direction: row;
        align-items: center;
        
        .wrapper {
            max-width: 1200px;
            width: calc(100vw - 80px);
            margin: auto;

            .title {
                line-height: 65px;
                -webkit-text-stroke: 1px rgb(255 255 255 / 40%);

                span {
                    display: block;
                    font-weight: 700;
                    font-size: 64px;
                    color: rgb(51, 51, 51);
                    text-shadow: rgb(0 0 0 / 9%) 0px 2px 20px;

                    &.subtitle {
                        font-weight: 400;
                    }
                }
            }

            .description {
                font-size: 22px;
                max-width: 400px;
                text-align: justify;
                padding-top: 40px;
                color: #181818;
                font-weight: 700;
                -webkit-text-stroke: 1px rgb(255 255 255 / 20%);
            }

            .button button {
                margin-top: 80px;
                font-weight: 300;
                font-family: 'Montserrat';
                background: linear-gradient(130deg, rgba(67,207,255,1) 0%, rgba(40,113,205,1) 83%, rgba(23,83,158,1) 100%);
                border: 1px solid #0c4ea1;
                color: white;
                font-size: 18px;
                height: 55px;
                width: 220px;
                border-radius: 4px;

                &:hover {
                    opacity: 0.8
                }
            }
        }

        @media(max-width: 800px) {
            .wrapper {
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;

                .title {
                    line-height: 50px;

                    span {
                        font-weight: 700;
                        font-size: 48px;
    
                        &.subtitle {
                            font-weight: 400;
                            font-size: 42px;
                        }
                    }
                }

                .description {
                    text-align: center;
                    font-size: 18px;
                }
            }
        }
	}
}